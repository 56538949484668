import { useDispatch } from 'react-redux';
import logo from '../majarra-logo.png';
import { openDialog } from '../store/actions/dialogs';
import {
    ChartSquareBarIcon,
    DocumentTextIcon,
    MenuIcon,
    QuestionMarkCircleIcon
} from '@heroicons/react/outline'
import { useCallback } from 'react';
import { MajarraButton } from './button';
import ReactGA from "react-ga4";


export function TopNavContent({ openHandler }: { openHandler: () => void }) {

    const dispatch = useDispatch();
    const openInfoDialog = useCallback(() => {
        dispatch(openDialog({
            dialogName: 'info'
        }));
        ReactGA.event("kalimat_open_info_dialog",{});
    },[]);

    const openDictionaryDialog = useCallback(() => {
        dispatch(openDialog({
            dialogName: 'dictionaries'
        }));
        ReactGA.event("kalimat_open_dictionary_dialog",{});
    },[]);

    const openStatsDialog = useCallback(() => {
        dispatch(openDialog({
            dialogName: 'stats'
        }));
        ReactGA.event("kalimat_open_stats_dialog",{});
    },[]);

    const shouldHideNavigation = (window.location.pathname) === '/articles';
    // console.log(window.location.pathname);


    return (
        <div
            className={'py-4 px-4 grid grid-cols-3 items-center fixed top-0 left-0 bg-white w-full z-[12] border-b-[1px] border-[#E8E9EA]'}
        >

            <div className="flex items-center">
                <MenuIcon
                    className="mx-2 h-7 w-7 cursor-pointer"
                    onClick={openHandler}
                />
                {!shouldHideNavigation && (
                    <DocumentTextIcon
                        className="mx-2 h-7 w-7 cursor-pointer md:hidden"
                        onClick={openDictionaryDialog}
                    />
                )}
                <div className="hidden md:block">
                    <a
                        rel="noreferrer"
                        onClick={(e) => {
                            ReactGA.event("kalimat_nav_click_majarra",{})
                        }}
                        className=""
                        target="_blank"
                        href="https://majarra.com/">
                        <MajarraButton variant="outlined">
                            {'اكتشف عالم مجرّة'}
                        </MajarraButton>
                    </a>
                </div>
            </div>

            <div
                className={'justify-self-center'}
            >
                <a
                    rel="noreferrer"
                    className=""
                    onClick={(e) => {
                        ReactGA.event("kalimat_nav_click_majarra_img",{})
                    }}
                    target="_blank"
                    href="https://majarra.com/">
                    <img
                        width={"85px"}
                        height={"25px"}
                        src={logo}
                        alt={"مجرة"}
                    />
                </a>
            </div>

            {!shouldHideNavigation && (
                <div className="flex justify-self-end">
                    <QuestionMarkCircleIcon
                        className="mx-2 h-7 w-7 cursor-pointer"
                        onClick={openInfoDialog}
                    />
                    <ChartSquareBarIcon
                        className="mr-2 h-7 w-7 cursor-pointer"
                        onClick={openStatsDialog}
                    />
                </div>

            )}

        </div>
    );
}
